import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import Tooltip from "./Tooltip";

const NumericField = (props) => {
  const {
    question,
    autoCommas,
    height,
    value,
    size,
    onChange,
    placeholder,
    min,
    max,
    maxDigits,
    allowDecimal,
    precision,
    disabledField,
    readOnlyField,
    ruleErrorText,
    ...otherProps
  } = props;

  const [error, setError] = useState(null);
  const [numericValue, setNumericValue] = useState(
    value !== undefined ? String(value) : ""
  );

  const handleInputChange = (event) => {
    let inputValue = event.target.value.replace(/[^0-9.-]/g, "");

    if (inputValue.startsWith("-")) {
      inputValue = "-" + inputValue.slice(1).replace(/-/g, "");
    }

    inputValue = inputValue.replace(/(?<=\d)-/g, "");

    setNumericValue(inputValue);

    if (!allowDecimal && inputValue.includes(".")) {
      setError("Decimal not allowed.");
    } else if (
      maxDigits !== undefined &&
      inputValue.replace(/\D/g, "").length > maxDigits
    ) {
      setError(`Value can have max ${maxDigits} digits.`);
    } else if (min !== undefined && inputValue < min && inputValue !== "") {
      setError(`Minimum value is  ${min}.`);
    } else if (max !== undefined && inputValue > max) {
      setError(`Maximum value is ${max}.`);
    } else {
      setError(null);
    }
  };

  const handleInputBlur = (event) => {
    if (error === null) {
      onChange(numericValue);
    }
  };

  useEffect(() => {
    setError(null);
    setNumericValue(String(value));
  }, [value, question]);

  const handleKeyDown = (event) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
      ".",
      "-",
    ];
    if (event.key === "." && event.target.value.includes(".")) {
      event.preventDefault();
      return;
    }

    if (
      event.key === " " ||
      (!allowedKeys.includes(event.key) && isNaN(Number(event.key)))
    ) {
      event.preventDefault();
    }

    if (allowDecimal && precision !== undefined) {
      const decimalIndex = event.target.value.indexOf(".");
      if (decimalIndex !== -1) {
        const digitsAfterDecimal = event.target.value.length - decimalIndex - 1;
        if (digitsAfterDecimal >= precision && event.key !== "Backspace") {
          event.preventDefault();
        }
      }
    }
  };

  return (
    <Grid sx={{ display: "flex" }} direction="row" alignItems="center">
      {question?.hideField ? (
        <></>
      ) : (
        <>
          {ruleErrorText && (
            <>
              {disabledField || readOnlyField ? (
                <Tooltip title={ruleErrorText}>
                  <TextField
                    type="text"
                    disabled={
                      otherProps?.type === "preview" || disabledField
                        ? true
                        : false
                    }
                    value={
                      autoCommas ? formatWithCommas(numericValue) : numericValue
                    }
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    placeholder={placeholder || ""}
                    error={!!error}
                    helperText={error}
                    sx={{
                      "& ::placeholder": {
                        color: "rgba(215, 215, 215, 1)",
                      },
                      // mt: 1,
                      // mb: 1,
                      width: size ? `${size * 8}px` : "100%",
                    }}
                    size={height}
                    InputProps={readOnlyField ? { readOnly: true } : {}}
                    {...otherProps}
                  />
                </Tooltip>
              ) : (
                <TextField
                  type="text"
                  disabled={
                    otherProps?.type === "preview" || disabledField
                      ? true
                      : false
                  }
                  value={
                    autoCommas ? formatWithCommas(numericValue) : numericValue
                  }
                  variant="outlined"
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  placeholder={placeholder || ""}
                  error={!!error}
                  helperText={error}
                  sx={{
                    "& ::placeholder": {
                      color: "rgba(215, 215, 215, 1)",
                    },
                    // mt: 1,
                    // mb: 1,
                    width: size ? `${size * 8}px` : "100%",
                  }}
                  size={height}
                  InputProps={readOnlyField ? { readOnly: true } : {}}
                  {...otherProps}
                />
              )}
            </>
          )}
          {!ruleErrorText && (
            <TextField
              type="text"
              disabled={
                otherProps?.type === "preview" || disabledField ? true : false
              }
              value={autoCommas ? formatWithCommas(numericValue) : numericValue}
              variant="outlined"
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              placeholder={placeholder || ""}
              error={!!error}
              helperText={error}
              sx={{
                "& ::placeholder": {
                  color: "rgba(215, 215, 215, 1)",
                },
                // mt: 1,
                // mb: 1,
                width: size ? `${size * 8}px` : "100%",
              }}
              size={height}
              InputProps={readOnlyField ? { readOnly: true } : {}}
              {...otherProps}
            />
          )}
          {otherProps.errorIndicator?.displayIndicator && (
            <Grid pl={2} style={{ color: "maroon" }}>{`<< `}</Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default NumericField;

export const formatWithCommas = (value) => {
  if (value === "" || value === null) {
    return "";
  }

  let [integerPart, fractionalPart] = value.split(".");
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (fractionalPart !== undefined) {
    return `${integerPart}.${fractionalPart}`;
  }

  return integerPart;
};
