import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  StyledEngineProvider,
} from "@mui/material";
import Checkbox from "../Common/Checkbox";
import { htmlParser } from "../../Utility/constants";
import HelpTextPopper from "../Common/Popper";
import NumericInputQuestion from "./NumericInputQuestion";
import TextInputQuestion from "./TextInputQuestion";
import DatePicker from "../Common/DatePicker";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import TextAreaQuestion from "./TextAreaQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import DropdownQuestion from "./DropdownQuestion";
import PhoneField from "../Common/PhoneField";
import StatesQuestion from "./StatesQuestion";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPageFilingsBySurveyFilingId,
  fetchQuestionsInPage,
  fetchSupPageFilings,
  saveAnswerOptions,
} from "../../pages/Survey/Survey.slice";
import CopyrightTwoToneIcon from "@mui/icons-material/CopyrightTwoTone";
import withCommentModal from "../BuilderViewEditRouter/withCommentModal";

const TextFieldConfig = {
  questionNo: "Question-1",
  questionType: "Text",
  size: 25,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  maxLength: 5,
  optionDirection: "row",
};

const TextAreaConfig = {
  questionNo: "Question-2",
  questionType: "Text Area",
  value: "text area input",
  size: 500,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  maxLength: 5,
  row: 10,
};

const NumericFieldConfig = {
  questionNo: "Question-3",
  questionType: "Numeric",
  value: "",
  size: 25,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  allowDecimal: false,
  precision: 4,
  maxDigits: 5,
  min: 20,
  max: 1000000,
};

const DateFieldConfig = {
  questionNo: "Question-4",
  questionType: "Date",
  size: 250,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const RadioConfig = {
  questionNo: "Question-5",
  questionType: "Radio",
  optionDirection: "row",
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const MultiChoiceConfig = {
  questionNo: "Question-6",
  questionType: "Checkbox",
  optionDirection: "column",
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const DropdownConfig = {
  questionNo: "Question-7",
  questionType: "Dropdown",
  size: 10,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const RenderCheckboxGrp = ({
  questionsGrp,
  correctionObj,
  getCorrectedData,
  pageId,
  secId,
  openCommentModal,
  reloadSec,
  hideComment = false,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const headings = questionsGrp.headings.map((heading) => heading.name);
  const questions = questionsGrp.questions.map((question) => question);
  const pagePayload = useSelector((state) => state.survey.pagePayload);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [additionalFieldVal, setAdditionalFieldVal] = useState({});
  const tableRef = useRef(null);
  const isOpde = useSelector((state) => state?.survey?.isOpde);
  const isbuilderLogin = useSelector((state) => state?.survey?.isbuilderLogin);
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFilingDto?.id
  );
  const supFilingId = useSelector(
    (state) => state?.survey?.supplementalFiling?.id
  );
  const modifiedByBuilder = useSelector(
    (state) => state.survey.builderUserData?.modifiedByUser
  );
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const surveyRespondentName = useSelector(
    (state) => state.survey.respondentDetails?.name
  );
  const updateStatuses = useSelector((state) => state.survey.updateStatuses);

  let pageFilings = useSelector((state) => state.survey.pageFilings);
  const supPageFilings = useSelector((state) => state?.survey?.supPageFilings);

  if (supPageFilings && supPageFilings.length > 0) {
    pageFilings = [...pageFilings, ...supPageFilings];
  }

  const getPageFilingId = (pageId) => {
    const pageFiling = pageFilings.find((obj) => obj.pageId === pageId);
    return pageFiling?.id;
  };

  const surFilingId = supPageFilings.some((supPageFiling) => {
    if (pageId) {
      return supPageFiling?.id === getPageFilingId(pageId);
    } else {
      return supPageFiling?.id === pagePayload?.pageFilingId;
    }
  })
    ? supFilingId
    : surveyFilingId;

  const clearAdditionalFields = (question, excCheckVal, excCheckField) => {
    if (question) {
      const exclusiveAnswerColumns = question.fields
        .filter((field) => field.fieldType.name === "CheckBox Field")
        .map((field) => {
          const exclusiveFieldValue = field.fieldValues.find(
            (fieldValue) => fieldValue.exclusiveAnswer === true
          );
          return exclusiveFieldValue.sequence + 1;
        });

      const additionalFields = question.fields.filter(
        (field) =>
          field.fieldType.name !== "CheckBox Field" &&
          !exclusiveAnswerColumns.includes(field.columnNumber)
      );

      const fieldsArray = [];
      const valsArray = [];

      additionalFields.forEach((field) => {
        setAdditionalFieldVal((prevValues) => ({
          ...prevValues,
          [field.id]: "",
        }));
        fieldsArray.push(field);
        valsArray.push("");
      });

      const pipedResponse = (options, field) => {
        return options.length > 0
          ? options.map((item) => item).join("|") + "|"
          : "";
      };

      fieldsArray.push(excCheckField);
      valsArray.push(pipedResponse(excCheckVal));

      saveFieldResponse(valsArray, fieldsArray);
    }
  };

  const handleCheckboxChange = (question, fieldValue, field) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedSelectedOptions = { ...prevSelectedOptions };
      if (!updatedSelectedOptions[question.id]) {
        updatedSelectedOptions[question.id] = [];
      }

      if (fieldValue?.exclusiveAnswer) {
        if (
          updatedSelectedOptions[question.id].some(
            (item) => item === fieldValue.allowableValue
          )
        ) {
          updatedSelectedOptions[question.id] = [];
          saveResponse(updatedSelectedOptions[question.id], field);
        } else {
          updatedSelectedOptions[question.id] = [fieldValue.allowableValue];
          clearAdditionalFields(
            question,
            updatedSelectedOptions[question.id],
            field
          );
        }
      } else {
        if (
          updatedSelectedOptions[question.id].some(
            (item) => item === fieldValue.allowableValue
          )
        ) {
          updatedSelectedOptions[question.id] = updatedSelectedOptions[
            question.id
          ].filter((item) => item !== fieldValue.allowableValue);
        } else {
          let exOpts = field?.fieldValues?.filter((item) =>
            updatedSelectedOptions[question.id]?.includes(item.allowableValue)
          );
          if (exOpts.some((item) => item.exclusiveAnswer)) {
            updatedSelectedOptions[question.id] = [fieldValue.allowableValue];
          } else {
            updatedSelectedOptions[question.id].push(fieldValue.allowableValue);
          }
        }

        saveResponse(updatedSelectedOptions[question.id], field);
      }

      // if (updatedSelectedOptions[question.id].includes(fieldValue.allowableValue)) {
      //   updatedSelectedOptions[question.id] = updatedSelectedOptions[
      //     question.id
      //   ].filter((item) => item !== fieldValue.allowableValue);
      // } else {
      //   updatedSelectedOptions[question.id].push(fieldValue.allowableValue);
      // }

      return updatedSelectedOptions;
    });
  };

  const saveResponse = (options, field) => {
    const savedAnswer =
      options.length > 0 ? options.map((item) => item).join("|") + "|" : "";
    saveFieldResponse(savedAnswer, field);
  };

  const saveFieldResponse = async (values, fields) => {
    const isSingleValue = !Array.isArray(values) && !Array.isArray(fields);

    let SingleAnsObject;
    let MultipleAnsObject;

    if (isSingleValue) {
      SingleAnsObject = [
        {
          id: fields?.answer?.id ? fields?.answer?.id : null,
          value: values,
          loopIndex: 1,
          fieldId: fields.id,
          surveyFilingId: surFilingId,
          surveyRespondentId: surveyRespondentId,
          createdBy: "string",
        },
      ];
    } else {
      MultipleAnsObject = fields.map((field, index) => ({
        id: field?.answer?.id ? field?.answer?.id : null,
        value: values[index],
        loopIndex: 1,
        fieldId: field.id,
        surveyFilingId: surFilingId,
        surveyRespondentId: surveyRespondentId,
        createdBy: "string",
      }));
    }

    const processField = async (value, field) => {
      if (correctionObj?.page === "correction") {
        if (!isSingleValue) {
          for (let i = 0; i < values.length; i++) {
            const value = values[i];
            const field = fields[i];
            getCorrectedData(value, field);
          }
        } else {
          const value = values;
          const field = fields;
          getCorrectedData(value, field);
        }
      } else {
        const payload = {
          answers: isSingleValue ? SingleAnsObject : MultipleAnsObject,
          surveyFilingId: surFilingId,
          pageFilingId: pageId
            ? getPageFilingId(pageId)
            : pagePayload.pageFilingId,
          runValidations: false,
          modifiedBy:
            isOpde || isbuilderLogin ? modifiedByBuilder : surveyRespondentName,
          modifiedBySurveyRespondentId: surveyRespondentId,
          updateStatuses,
        };
        await dispatch(saveAnswerOptions(payload));

        if (!isSingleValue) {
          for (let i = 0; i < values.length; i++) {
            const field = fields[i];
            if (field?.isInSamePageDispRule) {
              if (isOpde) {
                reloadSec(secId);
              } else dispatch(fetchQuestionsInPage(pagePayload));
            }

            if (field?.isInPageDispRule) {
              surveyFilingId &&
                dispatch(fetchPageFilingsBySurveyFilingId(surveyFilingId));
              supFilingId && dispatch(fetchSupPageFilings(supFilingId));
            }
          }
        } else {
          const field = fields;
          if (field?.isInSamePageDispRule) {
            if (isOpde) {
              reloadSec(secId);
            } else dispatch(fetchQuestionsInPage(pagePayload));
          }

          if (field?.isInPageDispRule) {
            surveyFilingId &&
              dispatch(fetchPageFilingsBySurveyFilingId(surveyFilingId));
            supFilingId && dispatch(fetchSupPageFilings(supFilingId));
          }
        }
      }
    };

    processField(values, fields);
  };

  useEffect(() => {
    const addFields = {};
    questionsGrp?.questions.forEach((question) => {
      question.fields.forEach((field) => {
        if (field.fieldType.name !== "CheckBox Field") {
          addFields[field.id] = field.answer?.value;
        }
      });
    });

    setAdditionalFieldVal(addFields);
  }, [questionsGrp]);

  useEffect(() => {
    if (questionsGrp?.questions) {
      let options = {};
      questionsGrp.questions?.forEach((question) => {
        const questionId = question.id;
        let checkboxGrp = question?.fields?.filter(
          (field) => field.fieldType.name === "CheckBox Field"
        );
        if (checkboxGrp.length) {
          let ansVal = checkboxGrp[0]?.answer?.value
            ?.split("|")
            .filter(
              (item) => item !== null && item !== undefined && item !== ""
            );
          options[questionId] = ansVal;
        }
      });
      setSelectedOptions(options);
    }
  }, [questionsGrp]);

  const handleNumericChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const dateChanged = (value, field) => {
    saveFieldResponse(value, field);
  };

  const phoneNumChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const handleTextChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const textAreaChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const handleRadioChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const handleDropdownChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const stateFieldChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const checkboxOptionChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const fieldComponents = (question, field) => {
    const fieldType = field.fieldType.name;
    let fieldComponent;
    switch (fieldType) {
      case "Simpletext Field":
        fieldComponent = () => (
          <TextInputQuestion
            disabledField={field?.disabledField}
            fieldConfig={{
              ...TextFieldConfig,
              ...field,
              value: additionalFieldVal[field.id] || "",
            }}
            type={otherProps.type}
            errorIndicator={getErrorRule(field)}
            question={field}
            onChange={(value) => {
              handleTextChange(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
            height="small"
          />
        );
        break;
      case "Textarea Field":
        fieldComponent = () => (
          <TextAreaQuestion
            disabledField={field?.disabledField}
            question={field}
            type={otherProps.type}
            fieldConfig={{
              ...TextAreaConfig,
              ...field,
              value: additionalFieldVal[field.id] || "",
            }}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => {
              textAreaChange(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
          />
        );
        break;
      case "Numeric Field":
        fieldComponent = () => (
          <NumericInputQuestion
            disabledField={field?.disabledField}
            fieldConfig={{
              ...NumericFieldConfig,
              ...field,
              value: additionalFieldVal[field.id] || "",
            }}
            type={otherProps.type}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => {
              handleNumericChange(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
            height="small"
          />
        );
        break;
      case "Date Field":
        fieldComponent = () => (
          <DatePicker
            disabledField={field?.disabledField}
            value={field?.answer ? field?.answer.value : ""}
            question={field}
            type={otherProps.type}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => dateChanged(value, field)}
          />
        );
        break;
      case "Radioyn Field":
        fieldComponent = () => (
          <SingleChoiceQuestion
            disabledField={field?.disabledField}
            fieldConfig={{
              ...RadioConfig,
              ...field,
            }}
            type={otherProps.type}
            qesType="y/n"
            value={additionalFieldVal[field.id] || ""}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => {
              handleRadioChange(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
          />
        );
        break;
      case "CheckBox Field":
        fieldComponent = () => (
          <MultipleChoiceQuestion
            disabledField={field?.disabledField}
            fieldConfig={MultiChoiceConfig}
            value={field?.answer ? field?.answer.value : ""}
            question={field}
            type={otherProps.type}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => checkboxOptionChange(value, field)}
          />
        );
        break;
      case "State Field":
        fieldComponent = () => (
          <StatesQuestion
            disabledField={field?.disabledField}
            fieldConfig={DropdownConfig}
            type={otherProps.type}
            value={field?.answer ? field?.answer.value : ""}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => stateFieldChange(value, field)}
          />
        );
        break;
      case "Phone Field":
        fieldComponent = () => (
          <PhoneField
            question={field}
            disabledField={field?.disabledField}
            placeholder={"(XXX) XXX-XXXX"}
            type={otherProps.type}
            value={field?.answer ? field?.answer.value : null}
            size={field.size}
            errorIndicator={getErrorRule(field)}
            onChange={phoneNumChange}
          />
        );
        break;
      case "Combobox Field":
        fieldComponent = () => (
          <DropdownQuestion
            disabledField={field?.disabledField}
            fieldConfig={{
              ...DropdownConfig,
              ...field,
              value: additionalFieldVal[field.id] || "",
            }}
            type={otherProps.type}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => {
              handleDropdownChange(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
            height="small"
          />
        );
        break;
      default:
        fieldComponent = () => (
          <Typography variant="h4">Under Development</Typography>
        );
        break;
    }

    const alignmentConfig = {
      "right-left": {
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
      },
      "right-center": {
        flexDirection: "row-reverse",
        justifyContent: "center",
      },
      "right-right": {
        flexDirection: "row-reverse",
        justifyContent: "flex-start",
      },
      "left-left": { flexDirection: "row", justifyContent: "flex-start" },
      "left-center": { flexDirection: "row", justifyContent: "center" },
      "left-right": { flexDirection: "row", justifyContent: "flex-end" },
      "Above-left": { flexDirection: "column", alignContent: "flex-start" },
      "Above-center": { flexDirection: "column", alignContent: "center" },
      "Above-right": { flexDirection: "column", alignContent: "flex-end" },
      "Below-left": {
        flexDirection: "column-reverse",
        alignContent: "flex-start",
      },
      "Below-center": {
        flexDirection: "column-reverse",
        alignContent: "center",
      },
      "Below-right": {
        flexDirection: "column-reverse",
        alignContent: "flex-end",
      },
    };

    const getNamePosition = (namePos) => {
      if ((namePos && namePos === "No Title") || !namePos) return "left";
      else return namePos;
    };

    let alignmentKey = `${getNamePosition(field.namePosition)}-${
      field.horizontalAlign || "left"
    }`;

    let { flexDirection, justifyContent, alignContent } =
      alignmentConfig[alignmentKey] || {};

    if (field.fieldFlow === "NL") {
      flexDirection = "column";
    }
    return (
      <Grid
        item
        container
        justifyContent={justifyContent}
        flexDirection={flexDirection}
        alignContent={alignContent}
      >
        {field.name &&
          field.namePosition !== "No Title" &&
          field.namePosition && (
            <Grid item className="heading-container">
              <Typography variant="label">{htmlParser(field.name)}</Typography>
            </Grid>
          )}

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          item
        >
          {fieldComponent(field)}
        </Grid>
      </Grid>
    );
  };

  const getErrorRule = (field) => {
    if (
      correctionObj?.page === "correction" &&
      correctionObj?.fieldIds.includes(field?.id)
    ) {
      return { displayIndicator: true };
    }
    return null;
  };

  const tableData = questions.map((question) => {
    const row = [];

    const firstRow = [];
    firstRow.push(
      <div style={{ display: "flex", alignItems: "center" }}>
        {isOpde && !hideComment && (
          <CopyrightTwoToneIcon
            style={{
              margin: "0 5px 3px 0",
              color: "#003087",
              cursor: "pointer",
            }}
            onClick={() => openCommentModal(secId, pageId, question.id)}
          />
        )}
        <Typography>
          {htmlParser(
            question.descriptionPosition !== null ||
              question.descriptionPosition !== "No Title"
              ? (question.label || "") + " " + (question.name || "")
              : (question.label || "") + " " + (question.description || "")
          )}
          {question?.helpText && (
            <HelpTextPopper question={question}></HelpTextPopper>
          )}
        </Typography>
      </div>
    );
    row.push(firstRow);

    const checkHistoricalVal = (field, fieldValue) => {
      const valuesArray = field?.historicalAnswer?.value.split("|");
      if (valuesArray?.includes(fieldValue.allowableValue)) {
        return true;
      } else {
        return false;
      }
    };

    question.fields.forEach((field, index) => {
      if (field.fieldType.name === "CheckBox Field") {
        field.fieldValues.forEach((fieldValue) =>
          row.push([
            <FormControlLabel
              disabled={
                otherProps?.type === "preview" || field?.disabledField
                  ? true
                  : false
              }
              control={
                <Checkbox
                  onChange={() =>
                    handleCheckboxChange(question, fieldValue, field)
                  }
                  historicalVal={checkHistoricalVal(field, fieldValue)}
                  value={field.fieldValues[0].allowableValue}
                  checked={
                    selectedOptions[question.id]?.includes(
                      fieldValue.allowableValue
                    ) || false
                  }
                />
              }
              label={
                <>
                  {getErrorRule(field) && (
                    <span style={{ color: "maroon" }}>{`<< `}</span>
                  )}
                </>
              }
              style={{ marginLeft: "15px" }}
            />,
          ])
        );
      } else if (
        field.fieldType.name !== "CheckBox Field" &&
        field.columnNumber !== null
      ) {
        row[field.columnNumber]?.push(<>{fieldComponents(question, field)}</>);
      }
    });
    row.questionId = question.id;
    return row;
  });

  return (
    <StyledEngineProvider injectFirst>
      <Card className="card">
        <CardContent className="card-header">
          <Grid container direction={"column"}>
            <Grid
              item
              // alignSelf={TextFieldConfig.alignQuestion}
              className="question-container"
            >
              <Typography variant="question">
                {htmlParser(
                  questionsGrp.descriptionPosition !== null ||
                    questionsGrp.descriptionPosition !== "No Title"
                    ? (questionsGrp.groupLabel || "") +
                        " " +
                        (questionsGrp.name || "")
                    : (questionsGrp.groupLabel || "") +
                        " " +
                        (questionsGrp.description || "")
                )}
                {questionsGrp?.helpText && (
                  <HelpTextPopper question={questionsGrp}></HelpTextPopper>
                )}
              </Typography>
            </Grid>
          </Grid>
          <div>
            <Typography variant="h6">
              {htmlParser(
                questionsGrp.descriptionPosition !== null ||
                  questionsGrp.descriptionPosition !== "No Title"
                  ? questionsGrp.description || ""
                  : ""
              )}
            </Typography>
          </div>
        </CardContent>
        <CardContent
          className="card-content"
          sx={{ backgroundColor: "#fff !important" }}
        >
          <Grid item>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <FormControl component="fieldset">
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      {headings.map((heading, index) => (
                        <th
                          key={heading}
                          style={{
                            textAlign: index === 0 ? "left" : "center",
                            width:
                              index === 0
                                ? "40%"
                                : `${60 / (headings.length - 1)}%`,
                          }}
                        >
                          {htmlParser(heading)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                </table>
                <Box
                  sx={{
                    // marginX: " -10px",
                    // minHeight: "350px",
                    overflowY: "scroll",
                    height:
                      tableRef?.current?.offsetHeight <= 600 ? "100%" : "600px",
                    scrollbarWidth: "thin",
                    scrollbarColor: "rgba(0, 48, 135, 1) white",
                    "&::-webkit-scrollbar": {
                      width: 3,
                      backgroundColor: "white",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0, 48, 135, 1)",
                      borderRadius: 4,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "@media print": {
                      height: "100%",
                    },
                  }}
                >
                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tbody ref={tableRef}>
                      {tableData.map((row, rowIndex) => (
                        <tr
                          key={rowIndex}
                          style={{
                            borderBottom: "1px solid #ccc",
                          }}
                          id={row.questionId}
                        >
                          {row.map((cell, cellIndex) => (
                            <td
                              key={cellIndex}
                              style={{
                                textAlign: cellIndex === 0 ? "left" : "center",
                                verticalAlign: "top",
                                padding: "2px",
                                width:
                                  cellIndex === 0
                                    ? "40%"
                                    : `${60 / (row.length - 1)}%`,
                              }}
                            >
                              {cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              </FormControl>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </StyledEngineProvider>
  );
};

// export default RenderCheckboxGrp;
export default withCommentModal(RenderCheckboxGrp);
