import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Logo from "../../assets/aha-header-logo.png";
import HeaderHome from "../../assets/header-home.svg";
import HeaderReports from "../../assets/header-reports.svg";
import HeaderSettings from "../../assets/header-settings.svg";
import HeaderHelp from "../../assets/header-help.svg";
import UserImg from "../../assets/user.png";
import PrintIcon from "@mui/icons-material/Print";
import ListIcon from "@mui/icons-material/List";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, InputBase } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./Navbar.scss";
import { setShowLanding } from "../../pages/Survey/Survey.slice";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PersonIcon from "@mui/icons-material/Person";
import { APP_URL, BASE_URL } from "../../Utility/constants";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "#717579",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: "3px solid #EEEEEE",
    borderRadius: "30px",
    borderColor: "#EEEEEE",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },
  },
}));

const AppBar = styled(
  MuiAppBar,
  {}
)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export default function Navbar({ toggleSidebar }) {
  const respondentName = useSelector(
    (state) => state?.survey?.respondentDetails?.name
  );
  const userData = useSelector((state) => state?.survey?.loggedInUserData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const navigate = useNavigate();
  const modifiedByBuilder = useSelector(
    (state) => state.survey.builderUserData?.modifiedByUser
  );
  const isOpde = useSelector((state) => state?.survey?.isOpde);
  const isbuilderLogin = useSelector((state) => state?.survey?.isbuilderLogin);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const headerAlignStyles = { display: "flex", alignItems: "center" };
  const headerBtnStyles = {
    my: 2,
    display: "flex",
    marginRight: "25px",
    textTransform: "none",
  };

  const [anchorElReport, setAnchorElReport] = React.useState(null);
  const open = Boolean(anchorElReport);
  const allowCloseTaker = isbuilderLogin || isOpde ? true : false;
  const generatePDF = async () => {
    const body = document.body;
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    await html2canvas(body, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const scaleFactor = 190 / canvas.width;

      pdf.addImage(imgData, "JPEG", 10, 10, canvas.width * scaleFactor);

      pdf.save("your-file-name.pdf");
    });
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleClose = () => {
    setAnchorElReport(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    handleMenuClose();
    handleMobileMenuClose();
    window.location.href = `${APP_URL}/logout`;
  };

  const handleCloseTaker = () => {
    window.open("about:blank", "_self");
    window.close();
  };
  const handleAccount = () => {
    handleMenuClose();
    handleMobileMenuClose();
    navigate("/account");
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    toggleSidebar(!sidebarOpen);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleAccount}>Account</MenuItem> */}
      {!allowCloseTaker && <MenuItem onClick={handleLogout}>Logout</MenuItem>}
      {allowCloseTaker && <MenuItem onClick={handleCloseTaker}>Close</MenuItem>}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem onClick={handleAccount}>
        <IconButton size="large" color="inherit">
          <Badge>
            <AccountCircle />
          </Badge>
        </IconButton>
        <p>Account</p>
      </MenuItem> */}
      {!allowCloseTaker && (
        <MenuItem onClick={handleLogout}>
          <IconButton size="large" color="inherit">
            <Badge>
              <LogoutIcon />
            </Badge>
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      )}
      {allowCloseTaker && (
        <MenuItem onClick={handleCloseTaker}>
          <IconButton size="large" color="inherit">
            <Badge>
              <LogoutIcon />
            </Badge>
          </IconButton>
          <p>Close</p>
        </MenuItem>
      )}
    </Menu>
  );

  const dispatch = useDispatch();

  const homeClicked = () => {
    dispatch(setShowLanding(true));
    navigate(`/`, { state: { showFilingList: false } });
  };

  const reportsClicked = (event) => {
    // navigate("/");
    setAnchorElReport(event.currentTarget);
  };
  const navigateToSurveyPrint = () => {
    handleClose();
    navigate("/print-survey");
  };
  const navigateToGlossary = () => {
    handleClose();
    navigate("/glossary");
  };
  const settingsClicked = () => {
    navigate("/admin");
  };
  const helpClicked = () => {
    navigate("/help");
  };
  const gotoFilingList = () => {
    // dispatch(setShowLanding(false));
    navigate(`/`, { state: { showFilingList: true } });
    window.location.href = APP_URL;
    window.location.reload();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          backgroundColor: "#FBFBFB",
          height: "100px",
          boxShadow:
            "0px 1px 2px -1px rgba(0,0,0,0.2), 0px 0px 2px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
        position="fixed"
      >
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleSidebarToggle}
          >
            <MenuIcon />
          </IconButton> */}
          <Toolbar>
            <Box
              component="img"
              className="header-logo"
              alt="Your logo."
              src={Logo}
            />
          </Toolbar>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "right",
            }}
          >
            <Button
              color="headerMenu"
              disabled={isOpde ? true : respondentName ? false : true}
              sx={headerBtnStyles}
              onClick={() => homeClicked()}
            >
              <div style={headerAlignStyles}>
                <span className="headerIcon" style={headerAlignStyles}>
                  <Box component="img" alt="Home icon" src={HeaderHome} />
                </span>
                <Typography sx={headerAlignStyles} variant="headerMenu">
                  Home
                </Typography>
              </div>
            </Button>
            <Button
              disabled={isOpde ? true : respondentName ? false : true}
              color="headerMenu"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={headerBtnStyles}
              onClick={(event) => reportsClicked(event)}
            >
              <div style={headerAlignStyles}>
                <span className="headerIcon" style={headerAlignStyles}>
                  <PrintIcon sx={{ fontSize: 31 }} />
                </span>
                <Typography sx={headerAlignStyles} variant="headerMenu">
                  Print
                </Typography>
              </div>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElReport}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={navigateToSurveyPrint}>Print Survey</MenuItem>
              <MenuItem onClick={navigateToGlossary}>Print Glossary</MenuItem>
            </Menu>
            <Button
              color="headerMenu"
              disabled={isOpde ? true : respondentName ? false : true}
              sx={headerBtnStyles}
              onClick={() => settingsClicked()}
            >
              <div style={headerAlignStyles}>
                <span className="headerIcon" style={headerAlignStyles}>
                  <Box component="img" alt="Home icon" src={HeaderSettings} />
                </span>
                <Typography sx={headerAlignStyles} variant="headerMenu">
                  Admin
                </Typography>
              </div>
            </Button>
            <Button
              disabled={isOpde ? true : respondentName ? false : true}
              color="headerMenu"
              sx={headerBtnStyles}
              onClick={() => helpClicked()}
            >
              <div style={headerAlignStyles}>
                <span className="headerIcon" style={headerAlignStyles}>
                  <Box component="img" alt="Home icon" src={HeaderHelp} />
                </span>
                <Typography sx={headerAlignStyles} variant="headerMenu">
                  Help
                </Typography>
              </div>
            </Button>
            {!modifiedByBuilder && (
              <Button
                disabled={isOpde ? true : respondentName ? false : true}
                color="headerMenu"
                sx={headerBtnStyles}
                onClick={() => gotoFilingList()}
              >
                <div style={headerAlignStyles}>
                  <span className="headerIcon" style={headerAlignStyles}>
                    <ListIcon sx={{ fontSize: 31 }} />
                  </span>
                  <Typography sx={headerAlignStyles} variant="headerMenu">
                    Filing List
                  </Typography>
                </div>
              </Button>
            )}
            {/* {userData?.selectableSurveyFilings?.length && (
              <Button
                color="headerMenu"
                disabled={respondentName ? false : true}
                sx={headerBtnStyles}
                onClick={() => gotoFilingList()}
              >
                <div style={headerAlignStyles}>
                  <span className="headerIcon" style={headerAlignStyles}>
                    <ListIcon sx={{ fontSize: 31 }} />
                  </span>
                  <Typography sx={headerAlignStyles} variant="headerMenu">
                    My Filings
                  </Typography>
                </div>
              </Button>
            )} */}
            {/* <Box sx={headerBtnStyles}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon style={{ height: "30px" }} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
            <Box sx={headerBtnStyles}>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon color="headerMenu" />
                </Badge>
              </IconButton>
            </Box> */}
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              // disabled={respondentName ? false : true}
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="headerMenu"
              sx={{ borderRadius: "13%", border: "1px solid #eeeeee" }}
            >
              {/* <AccountCircle /> */}
              <div style={headerAlignStyles}>
                <span className="headerIcon" style={headerAlignStyles}>
                  <PersonIcon
                    sx={{
                      width: 50,
                      height: 50,
                      color: "white",
                      background: "#B6C3DB",
                    }}
                  />
                </span>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Typography sx={{ color: "#171B1E" }} variant="question">
                    {respondentName}
                  </Typography>
                  {/* <Typography
                    sx={{
                      color: "headerMenu",
                      display: "flex",
                      justifyContent: "start",
                    }}
                    variant="h6"
                  >
                    Employee
                  </Typography> */}
                </Box>
              </div>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="headerMenu"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
