import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextArea from "../Common/TextArea";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider } from "@mui/material/styles";
import "./QuestionTypes.scss";

const TextAreaQuestion = ({
  question,
  fieldConfig,
  onChange,
  disabledField,
  ...otherProps
}) => {
  return (
    <>
      <Grid item>
        <TextArea
          disabledField={disabledField}
          type={otherProps.type}
          value={fieldConfig?.value}
          minRows={5}
          maxLength={fieldConfig?.maxLength || 4000}
          size={fieldConfig?.size}
          onChange={onChange}
          question={question}
          readOnlyField={question?.readOnlyField}
          {...otherProps}
        />
      </Grid>
    </>
  );
};

export default TextAreaQuestion;
