import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  StyledEngineProvider,
  Typography,
  Modal,
} from "@mui/material";
import RenderRadioGrp from "../../components/QuestionTypes/RenderRadioGrp";
import RenderCheckboxGrp from "../../components/QuestionTypes/RenderCheckboxGrp";
import RenderCommonGrp from "../../components/QuestionTypes/RenderCommonGrp";
import RenderQuestion from "../../components/QuestionTypes/RenderQuestion";
import { getTextFromHTML, htmlParser } from "../../Utility/constants";
import HelpTextPopper from "../../components/Common/Popper";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchSectionData,
  resetSectionsData,
  setTotalSectionData,
  fetchSectionsBySurveyIdAndRespondenetId,
  fetchPageFilingsBySurveyFilingId,
  getSurveyFiling,
  fetchSurveyFiling,
  fetchSupSectionFiling,
  fetchSupPages,
  fetchSupPageFilings,
  fetchSupplementalFiling,
  validateSurvey,
  setShowErrorPage,
  fetchSectionErrors,
  setDataIds,
  setBuilderOrgId,
} from "../../pages/Survey/Survey.slice";
import GetComments from "./GetComments";

const Opde = () => {
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [thankYouMsg, setThankYouMsg] = useState("");
  const [openComments, setOpenComments] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const surveyId = useSelector((state) => state.survey.activeSurveys[0]?.id);
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const builderSurveyFilingId = Number(state?.surveyFilingId);
  const builderOrgid = state?.builderOrgId;
  const surveyName = useSelector(
    (state) => state.survey.activeSurveys[0]?.name
  );
  const sectionsPageData = useSelector((state) => state.survey?.sectionsData);
  const [surveyData, setSurveyData] = useState([]);
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const [sectionsLoading, setSectionsLoading] = useState(false);
  const [sectionFilingsLoaded, setSectionFilingsLoaded] = useState(false);
  const [supSectionFilingLoaded, setSupSectionFilingLoaded] = useState(false);
  const [showValidationMsg, setShowValidationMsg] = useState(false);
  const [totalSecFilings, setTotalSecFilings] = useState([]);
  let sectionFilings = useSelector((state) => state.survey?.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );
  const surveyFiling = useSelector((state) => state.survey?.surveyFilingDto);
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFilingDto?.id
  );
  const supFilingId = useSelector(
    (state) => state.survey.supplementalFiling?.id
  );
  const orgName = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.name
  );
  const orgId = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.customId
  );

  let pageFilings = useSelector((state) => state.survey.pageFilings);
  const supPageFilings = useSelector((state) => state?.survey?.supPageFilings);
  const updateStatuses = useSelector((state) => state.survey.updateStatuses);

  if (supPageFilings && supPageFilings.length > 0) {
    pageFilings = [...pageFilings, ...supPageFilings];
  }

  useEffect(() => {
    if (builderOrgid) {
      dispatch(setBuilderOrgId(Number(builderOrgid)));
    }
  }, [dispatch, builderOrgid]);

  useEffect(() => {
    if (sectionFilingsLoaded && supSectionFilingLoaded) {
      setTotalSecFilings([...sectionFilings, ...supSectionFiling]);
    }
  }, [sectionFilingsLoaded, supSectionFilingLoaded]);

  useEffect(() => {
    if (builderSurveyFilingId && surveyRespondentId) {
      const payload = {
        surveyFilingId: builderSurveyFilingId,
        surveyRespondentId: surveyRespondentId,
      };
      dispatch(fetchSectionsBySurveyIdAndRespondenetId(payload))
        .then(() => {
          setSectionFilingsLoaded(true);
        })
        .catch((error) => {
          console.error("Error fetching section filings:", error);
        });
    }
  }, [dispatch, builderSurveyFilingId, surveyRespondentId]);

  useEffect(() => {
    if (builderSurveyFilingId && surveyId && surveyRespondentId) {
      dispatch(fetchPageFilingsBySurveyFilingId(builderSurveyFilingId));
      dispatch(getSurveyFiling(builderSurveyFilingId));
      dispatch(
        fetchSurveyFiling({
          surveyId,
          surveyRespondentId,
        })
      );
    }
  }, [dispatch, builderSurveyFilingId, surveyId, surveyRespondentId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (builderSurveyFilingId) {
          const response = await dispatch(
            fetchSupplementalFiling(builderSurveyFilingId)
          );
          if (
            response.meta.requestStatus === "fulfilled" &&
            surveyRespondentId
          ) {
            if (response.payload && supFilingId) {
              const payload = {
                surveyFilingId: supFilingId,
                surveyRespondentId: surveyRespondentId,
              };
              dispatch(fetchSupPages(response?.payload?.survey?.id));
              dispatch(fetchSupPageFilings(response?.payload?.id));
              await dispatch(fetchSupSectionFiling(payload));
              setSupSectionFilingLoaded(true);
            } else if (!response.payload) {
              setSupSectionFilingLoaded(true);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching supplemental filing:", error);
      }
    };

    fetchData();
  }, [dispatch, surveyFiling, surveyRespondentId, supFilingId]);

  function surFilingId(secId) {
    return supSectionFiling.some(
      (supSecFiling) => supSecFiling?.sectionId === secId
    )
      ? supFilingId
      : builderSurveyFilingId;
  }

  useEffect(() => {
    setSectionsLoading(true);
    if (totalSecFilings.length > 0) {
      totalSecFilings.forEach(async (sec) => {
        if (surveyId && surFilingId(sec?.sectionId) && surveyRespondentId) {
          let payload = {
            surveyId: surveyId,
            surveyFilingId: surFilingId(sec?.sectionId),
            sectionId: sec?.sectionId,
            surveyRespondentId: surveyRespondentId,
          };

          await dispatch(fetchSectionData(payload)).then((data) => {
            dispatch(setTotalSectionData(data?.payload));
            setSectionsLoading(false);
          });
        }
      });
    }
    return () => {
      dispatch(resetSectionsData());
    };
  }, [totalSecFilings, surveyId, surveyRespondentId]);

  useEffect(() => {
    if (sectionFilings?.length === sectionsPageData?.length) {
      setSurveyLoaded(true);
    }
    if (sectionsPageData.length > 0) {
      setSurveyData(sectionsPageData);
    }
  }, [sectionsPageData]);

  function secFilingId(secId) {
    const secFiling = totalSecFilings.find((obj) => obj.section.id === secId);
    return secFiling?.id;
  }
  function PageFilingId(pageId) {
    const pageFiling = pageFilings.find((obj) => obj.pageId === pageId);
    return pageFiling?.id;
  }

  const showCommentsView = () => {
    setOpenComments(true);
  };
  const handleCloseCommentsModal = () => {
    setOpenComments(false);
  };
  const validatePage = async (section, page) => {
    await dispatch(
      setDataIds({
        secId: section.id,
        pgId: page?.page?.id,
      })
    );
    const payload = {
      // pageFilingId: PageFilingId(page?.page?.id),
      // pageId: page?.page?.id,
      surveyFilingId: Number(surFilingId(section.id)),
      // sectionId: section.id,
      // sectionFilingId: secFilingId(section.id),
      runAllSurveyValidations: true,
      runAllSectionValidations: true,
      runAllPageValidations: true,
      surveyRespondentId: surveyRespondentId,
      updateStatuses,
    };
    setShowValidationMsg(true);
    await dispatch(validateSurvey(payload))
      .then((res) => {
        if (res?.payload?.hasOwnProperty("errors")) {
          if (
            Array.isArray(res?.payload?.errors) &&
            res?.payload?.errors?.length > 0
          ) {
            navigate("/opde-error-list");
          } else if (supFilingId) {
            const surveyFilingId = res?.meta?.arg?.surveyFilingId;
            let newSurveyFilingId;
            if (surveyFilingId === Number(supFilingId)) {
              newSurveyFilingId = builderSurveyFilingId;
            } else if (surveyFilingId === Number(builderSurveyFilingId)) {
              newSurveyFilingId = supFilingId;
            }
            const newPayload = {
              ...payload,
              surveyFilingId: newSurveyFilingId,
            };
            dispatch(validateSurvey(newPayload))
              .then((res) => {
                if (res?.payload?.hasOwnProperty("errors")) {
                  if (
                    Array.isArray(res?.payload?.errors) &&
                    res?.payload?.errors?.length > 0
                  ) {
                    navigate("/opde-error-list");
                  } else navigate("/submit-survey");
                } else setShowValidationMsg(false);
              })
              .catch((error) => {
                console.error(error);
              });
          } else navigate("/submit-survey");
        } else setShowValidationMsg(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const reloadSec = async (secId) => {
    if (surveyId && secId && surveyRespondentId) {
      let payload = {
        surveyId: surveyId,
        surveyFilingId: surFilingId(secId),
        sectionId: secId,
        surveyRespondentId: surveyRespondentId,
      };

      await dispatch(fetchSectionData(payload)).then((data) => {
        dispatch(setTotalSectionData(data?.payload));
      });
    }
  };

  return showValidationMsg ? (
    <>
      <Box
        display="flex"
        color="primary"
        sx={{ height: "100vh", width: "100%", fontSize: "24px" }}
        alignItems="center"
        justifyContent="center"
      >
        Running validation rules…
      </Box>
    </>
  ) : (
    <Grid
      sx={{
        "@media print": {
          marginLeft: "10px",
        },
      }}
    >
      {sectionsLoading ? (
        <Box
          display="flex"
          sx={{ height: "100%", width: "100%" }}
          bgcolor="#d9e0ed"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <form ml={2}>
          <div
            style={{
              display: surveySubmitted ? "none" : "flex",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography
                  sx={{ fontSize: 20, fontWeight: "bold", marginBottom: 2 }}
                  color="primary"
                >
                  {orgName} ({orgId})
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => showCommentsView()}
                >
                  View Comments
                </Button>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography
                  sx={{ fontSize: 25, fontWeight: "bold" }}
                  color="primary"
                >
                  {getTextFromHTML(surveyName)}
                </Typography>
                <Divider
                  sx={{
                    backgroundColor: "black",
                  }}
                />
              </div>
            </div>
          </div>
          {surveyData &&
            surveyData.map((section) => (
              <div
                style={{
                  display: surveySubmitted ? "none" : "block",
                }}
              >
                <div
                  className="title"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      paddingBottom: "5px",
                    }}
                    color="primary"
                  >
                    {section?.name}
                  </Typography>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => validateSection(section)}
                  >
                    Validate
                  </Button> */}
                </div>
                {Object.values(section?.pages).map((page) => (
                  <>
                    <Grid container justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => validatePage(section, page)}
                      >
                        Validate
                      </Button>
                    </Grid>
                    {page?.questionGroups.map((qes) => (
                      <>
                        {qes.groupType.name === "radiogrp" && (
                          <RenderRadioGrp
                            questionsGrp={qes}
                            pageId={page?.page?.id}
                            secId={section?.id}
                            reloadSec={reloadSec}
                          />
                        )}
                        {qes.groupType.name === "checkboxgrp" && (
                          <RenderCheckboxGrp
                            questionsGrp={qes}
                            pageId={page?.page?.id}
                            secId={section?.id}
                            reloadSec={reloadSec}
                          />
                        )}
                        {qes.groupType.name === "commongrp" && (
                          <RenderCommonGrp
                            questionsGrp={qes}
                            pageId={page?.page?.id}
                            secId={section?.id}
                            reloadSec={reloadSec}
                          />
                        )}
                        {qes.groupType.name !== "radiogrp" &&
                          qes.groupType.name !== "checkboxgrp" &&
                          qes.groupType.name !== "commongrp" &&
                          qes.questions &&
                          qes.questions.length > 0 &&
                          qes.questions.length === 1 &&
                          qes.questions.map((question) => {
                            return (
                              <RenderQuestion
                                question={question}
                                multiple={false}
                                pageId={page?.page?.id}
                                secId={section?.id}
                                reloadSec={reloadSec}
                              />
                            );
                          })}
                        {qes.groupType.name !== "radiogrp" &&
                          qes.groupType.name !== "checkboxgrp" &&
                          qes.groupType.name !== "commongrp" &&
                          qes.questions?.length > 1 && (
                            <>
                              <StyledEngineProvider injectFirst>
                                <Card className="card">
                                  <CardContent className="card-header">
                                    <Grid container direction={"column"}>
                                      <Grid
                                        item
                                        alignSelf="start"
                                        className="question-container"
                                      >
                                        <Typography variant="question">
                                          {htmlParser(
                                            qes.descriptionPosition !== null ||
                                              qes.descriptionPosition !==
                                                "No Title"
                                              ? (qes.label || "") +
                                                  " " +
                                                  (qes.name || "")
                                              : (qes.label || "") +
                                                  " " +
                                                  (qes.description || "")
                                          )}
                                          {qes?.helpText && (
                                            <HelpTextPopper
                                              question={qes}
                                            ></HelpTextPopper>
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <div>
                                      <Typography variant="h6">
                                        {htmlParser(
                                          qes.descriptionPosition !== null ||
                                            qes.descriptionPosition !==
                                              "No Title"
                                            ? qes.description || ""
                                            : ""
                                        )}
                                      </Typography>
                                    </div>
                                  </CardContent>
                                  <CardContent
                                    className="card-content"
                                    sx={{
                                      backgroundColor: "#fff !important",
                                    }}
                                  >
                                    {qes.questions.map((question) => {
                                      return (
                                        <RenderQuestion
                                          question={question}
                                          multiple={true}
                                          pageId={page?.page?.id}
                                          secId={section?.id}
                                          reloadSec={reloadSec}
                                        />
                                      );
                                    })}
                                  </CardContent>
                                </Card>
                              </StyledEngineProvider>
                            </>
                          )}
                      </>
                    ))}
                  </>
                ))}
              </div>
            ))}
        </form>
      )}
      <Modal open={openComments} onClose={handleCloseCommentsModal}>
        <GetComments handleCloseComments={handleCloseCommentsModal} />
      </Modal>
    </Grid>
  );
};

export default Opde;
