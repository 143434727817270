import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  RadioGroup,
  StyledEngineProvider,
} from "@mui/material";
import RadioButton from "../Common/RadioButton";
import { htmlParser } from "../../Utility/constants";
import HelpTextPopper from "../Common/Popper";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPageFilingsBySurveyFilingId,
  fetchQuestionsInPage,
  fetchSupPageFilings,
  saveAnswerOptions,
} from "../../pages/Survey/Survey.slice";
import CopyrightTwoToneIcon from "@mui/icons-material/CopyrightTwoTone";
import withCommentModal from "../BuilderViewEditRouter/withCommentModal";

const RenderRadioGrp = ({
  questionsGrp,
  getCorrectedData,
  pageId,
  secId,
  openCommentModal,
  reloadSec,
  hideComment = false,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const headings = questionsGrp.headings.map((heading) => heading.name);
  const questions = questionsGrp.questions.map((question) => question);
  const pagePayload = useSelector((state) => state.survey.pagePayload);
  const isOpde = useSelector((state) => state?.survey?.isOpde);
  const isbuilderLogin = useSelector((state) => state?.survey?.isbuilderLogin);
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFilingDto?.id
  );
  const supFilingId = useSelector(
    (state) => state?.survey?.supplementalFiling?.id
  );

  const [selectedOptions, setSelectedOptions] = useState([]);

  const modifiedByBuilder = useSelector(
    (state) => state.survey.builderUserData?.modifiedByUser
  );
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const surveyRespondentName = useSelector(
    (state) => state.survey.respondentDetails?.name
  );
  const updateStatuses = useSelector((state) => state.survey.updateStatuses);

  let pageFilings = useSelector((state) => state.survey.pageFilings);
  const supPageFilings = useSelector((state) => state?.survey?.supPageFilings);

  if (supPageFilings && supPageFilings.length > 0) {
    pageFilings = [...pageFilings, ...supPageFilings];
  }

  const getPageFilingId = (pageId) => {
    const pageFiling = pageFilings.find((obj) => obj.pageId === pageId);
    return pageFiling?.id;
  };

  const saveFieldResponse = async (value, field) => {
    if (otherProps?.correctionObj?.page === "correction") {
      getCorrectedData(value, field);
    } else {
      const surFilingId = supPageFilings.some((supPageFiling) => {
        if (pageId) {
          return supPageFiling?.id === getPageFilingId(pageId);
        } else {
          return supPageFiling?.id === pagePayload?.pageFilingId;
        }
      })
        ? supFilingId
        : surveyFilingId;

      const payload = {
        answers: [
          {
            id: field?.answer?.id ? field?.answer?.id : null,
            value: value,
            loopIndex: 1,
            fieldId: field.id,
            surveyFilingId: surFilingId,
            surveyRespondentId: surveyRespondentId,
            createdBy: "string",
          },
        ],
        surveyFilingId: surFilingId,
        pageFilingId: pageId
          ? getPageFilingId(pageId)
          : pagePayload.pageFilingId,
        runValidations: false,
        modifiedBy:
          isOpde || isbuilderLogin ? modifiedByBuilder : surveyRespondentName,
        modifiedBySurveyRespondentId: surveyRespondentId,
        updateStatuses,
      };

      await dispatch(saveAnswerOptions(payload));

      if (field?.isInSamePageDispRule) {
        if (isOpde) {
          reloadSec(secId);
        } else dispatch(fetchQuestionsInPage(pagePayload));
      }

      if (field?.isInPageDispRule) {
        surveyFilingId &&
          dispatch(fetchPageFilingsBySurveyFilingId(surveyFilingId));
        supFilingId && dispatch(fetchSupPageFilings(supFilingId));
      }
    }
  };

  const handleRadioChange = (question, value, field) => {
    const updatedSelectedOptions = [...selectedOptions];

    // Find the corresponding question in the array
    const questionIndex = updatedSelectedOptions.findIndex(
      (item) => item.questionName === question
    );

    if (questionIndex !== -1) {
      updatedSelectedOptions[questionIndex].selectedAns = value;
    }

    setSelectedOptions(updatedSelectedOptions);
    saveFieldResponse(value, field);
  };

  const handleRadioDoubleClick = (question, field) => {
    // Create a copy of the selectedOptions array
    const updatedSelectedOptions = [...selectedOptions];

    const questionIndex = updatedSelectedOptions.findIndex(
      (item) => item.questionName === question
    );

    if (questionIndex !== -1) {
      updatedSelectedOptions[questionIndex].selectedAns = null;
    }
    // Update the state variable with the modified array
    setSelectedOptions(updatedSelectedOptions);
    saveFieldResponse(null, field);
  };

  const isChecked = (questionName, value, field) => {
    return selectedOptions[questionName] == value;
  };

  useEffect(() => {
    if (questions) {
      const options = questionsGrp.questions?.map((question) => {
        return {
          questionName: question.name,
          selectedAns: question?.fields[0]?.answer
            ? question?.fields[0]?.answer?.value
            : null,
        };
      });
      setSelectedOptions(options);
    }
  }, [questionsGrp]);

  const getErrorRule = (field) => {
    if (
      otherProps.correctionObj?.page === "correction" &&
      otherProps.correctionObj?.fieldIds.includes(field?.id)
    ) {
      return { displayIndicator: true };
    }
    return null;
  };

  // const prepSelectedOpts = (question, fieldValue) => {
  //   if (question?.fields[0]?.answer) {
  //     setSelectedOptions((prevSelectedOptions) => ({
  //       ...prevSelectedOptions,
  //       [question.name]: question?.fields[0]?.answer,
  //     }));
  //   }
  // }

  return (
    <StyledEngineProvider injectFirst>
      <Card className="card">
        <CardContent className="card-header">
          <Grid container direction={"column"}>
            <Grid
              item
              // alignSelf={TextFieldConfig.alignQuestion}
              className="question-container"
            >
              <Typography variant="question">
                {htmlParser(
                  questionsGrp.descriptionPosition !== null ||
                    questionsGrp.descriptionPosition !== "No Title"
                    ? (questionsGrp.groupLabel || "") +
                        " " +
                        (questionsGrp.name || "")
                    : (questionsGrp.groupLabel || "") +
                        " " +
                        (questionsGrp.description || "")
                )}
                {questionsGrp?.helpText && (
                  <HelpTextPopper question={questionsGrp}></HelpTextPopper>
                )}
              </Typography>
            </Grid>
          </Grid>
          <div>
            <Typography variant="h6">
              {htmlParser(
                questionsGrp.descriptionPosition !== null ||
                  questionsGrp.descriptionPosition !== "No Title"
                  ? questionsGrp.description || ""
                  : ""
              )}
            </Typography>
          </div>
        </CardContent>
        <CardContent
          className="card-content"
          sx={{ backgroundColor: "#fff !important" }}
        >
          <Grid item>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <FormControl component="fieldset">
                <table style={{ borderCollapse: "collapse" }}>
                  <thead>
                    <tr style={{ borderBottom: "1px solid #ccc" }}>
                      {headings.map((heading, index) => (
                        <th
                          key={heading}
                          style={{
                            textAlign: index === 0 ? "left" : "center",
                          }}
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {questions.map((question) => (
                      <tr
                        key={question.id}
                        style={{ borderBottom: "1px solid #ccc" }}
                        id={question.id}
                      >
                        <td>
                          {isOpde && !hideComment && (
                            <CopyrightTwoToneIcon
                              style={{
                                margin: "0 5px -6px 0",
                                color: "#003087",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                openCommentModal(secId, pageId, question.id)
                              }
                            />
                          )}
                          {`${question.label}${question.name}`}
                        </td>
                        {question.fields[0].fieldValues.map((fieldValue) => (
                          <td
                            key={fieldValue.id}
                            style={{ textAlign: "center" }}
                          >
                            <RadioGroup
                              sx={{
                                alignItems: "center",
                              }}
                              onChange={() =>
                                handleRadioChange(
                                  question.name,
                                  fieldValue.name,
                                  question.fields[0]
                                )
                              }
                            >
                              <FormControlLabel
                                disabled={
                                  otherProps?.type === "preview" ? true : false
                                }
                                control={
                                  <RadioButton
                                    disabledField={
                                      question.fields[0].disabledField
                                    }
                                    type={otherProps.type}
                                    value={fieldValue.name}
                                    checked={
                                      selectedOptions.find(
                                        (item) =>
                                          item.questionName === question.name &&
                                          item.selectedAns === fieldValue.name
                                      ) !== undefined
                                    }
                                    flag={
                                      question.fields[0]?.historicalAnswer
                                        ?.value === fieldValue.name
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                onDoubleClick={() =>
                                  handleRadioDoubleClick(
                                    question.name,
                                    question.fields[0]
                                  )
                                }
                                style={{ marginLeft: "15px" }}
                              />
                            </RadioGroup>
                            {getErrorRule(question.fields[0]) && (
                              <Grid
                                pl={2}
                                style={{ color: "maroon" }}
                              >{`<< `}</Grid>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FormControl>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </StyledEngineProvider>
  );
};

// export default RenderRadioGrp;
export default withCommentModal(RenderRadioGrp);
